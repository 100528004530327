@font-face {
  font-family: 'Bluu Next';
  font-style: normal;
  font-named-instance: 'Regular';
  src: url(./fonts/BluuNext-Bold.otf) format("opentype"); 
}

@font-face {
  font-family: 'Lazar';
  font-style: normal;
  font-named-instance: 'Regular';
  src: url(./fonts/Lazar1389.otf) format("opentype"); 
}

@font-face {
  font-family: 'Nuernberg';
  font-style: normal;
  font-named-instance: 'Regular';
  src: url(./fonts/Nuernberg.ttf) format("truetype"); 
}

@font-face {
  font-family: 'NKDuy';
  font-style: normal;
  font-named-instance: 'Italic';
  src: url(./fonts/NKDuyMono-Italic.ttf) format("truetype"); 
}

@font-face {
  font-family: 'NKDuy';
  font-style: normal;
  font-named-instance: 'Regular';
  src: url(./fonts/NKDuyMono.ttf) format("truetype"); 
}

img {
  display: block;
  max-width: 90%;
  margin: auto;
  mask-size: contain;
  shape-margin: 30px;
  padding: 20px;
}

img[title="center"] {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 750px) {
  img[title="right"] {
    float: right;
  }
}

@media (min-width: 750px) {
  img[title="left"] {
    float: left;
  }
}

* {
  box-sizing: border-box;
}
